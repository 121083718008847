import React from "react";
import { Container, Breadcrumb, Button, Row, Col} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Post } from "../components/Core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Mais Qui Est BankBank?</title>
                <meta name="description" content="
              BankBank, c'est qui exactement? Découvrez la raison de l'existence de BankBank.fr" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Qui sommes-nous?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Qui Est BankBank?</Title>
			<StaticImage
		  	src='../images/qui-est-bankbank.png'
			width={1200}
			alt='Qui est BankBank?'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		
				<h2>
				Découvrez BankBank, votre comparateur des banques
				</h2>
				<p>La banque est au centre notre quotidien. C’est la plateforme sur laquelle repose tous nos achats et toutes nos transactions. Les banques traditionnelles avec un guichet ont pris la responsabilité de cette tâche durant des années mais internet, et le mobile, ont complètement bouleversé le paysage bancaire. 
				</p><p>
Ce changement s’est d’abord produit au niveau de la bourse avec des brokers 100% en ligne qui ont permis d’acheter et vendre des actions sans interlocuteur. Puis plus récemment la technologie s’est propagé à toute l’économie et des mots comme fintech, banque en ligne ou encore néo-banque sont devenus tendances.
</p><p>
Voilà, on y est! La banque à changer et l’offre bancaire n’a jamais été aussi grande. BankBank a été créé pour essayer de voir plus claire dans cet environnement rendu opaque par le matraquage publicitaire. 
</p><p>
La banque en ligne est-elle réellement 100% gratuite ? Si elle est gratuite, la différence entre chaque compte courant c’est quoi exactement ? Je fais quoi de mon liquide si la banque est seulement en ligne ? Combien vont coûter mes transactions quand je vais à l’étranger ? Et les chèques, ça marche toujours? Une banque en ligne pour mon entreprise, c’est possible aussi?
</p><p>
Tous les jours, nous sommes bombardés par de nouvelles questions de nos visiteurs et nous faisons notre mieux pour répondre sous forme d’articles, de comparatifs et de guides pratiques.
</p><p>
La digitalisation de la banque n’est qu’à son début et nous espérons que BankBank sera le guide qui vous accompagne durant toute votre vie bancaire.</p>
<Row>
	<Col><Link to={'/'}>
                <Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 60, backgroundColor: '#ffc107', borderColor: '#ffc107', color: '#29313d'}}>Je suis un particulier <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </Link></Col>
	<Col>		<Link to={'/banque-pro/'}>
                <Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 60, backgroundColor: '#ffc107', borderColor: '#ffc107', color: '#29313d'}}>Je suis un professionnel <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </Link></Col>
</Row>


		
		
		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
